export default function RingbaFontScale() {
  // ---------------------------------------------------------------------------

  function scaleFont() {
    document.documentElement.style.fontSize = `${Math.max(19.2, 0.01 * window.innerWidth)}px`;
  }

  addEventListener('resize', scaleFont);
  scaleFont();
}
