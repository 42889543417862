import RingbaGL, { RingbaWorld } from '~/webgl';
import RingbaConfig from './ringba-config';

// --

export default class Ringba extends RingbaWorld {
  // ---------------------------------------------------------------------------

  __config() {
    return RingbaConfig;
  }

  _create({ isTouch, onLoadProgress, onLoadComplete, onCursorDown, onCursorUp }) {
    this._isTouch = isTouch;
    // --
    this._onLoadProgress = onLoadProgress;
    this._onLoadComplete = onLoadComplete;

    this._cursorDownCallback = onCursorDown;
    this._cursorUpCallback = onCursorUp;

    // --
    RingbaGL().load.model('models/phone.glb', (model) => {
      model.scene.traverse((child) => {
        console.log('Model loaded:', child.name);
        switch (child.name) {
          case 'phone':
            this._story.action((scene, index) => {
              scene.setup(child.clone());
            });
            break;
        }
      });
    });
  }

  // ---------------------------------------------------------------------------

  _loadProgress(properties) {
    this._onLoadProgress(RingbaGL().load.progress);
  }

  _start() {
    this._onLoadProgress(1.0);
    this._onLoadComplete();
  }

  // ---------------------------------------------------------------------------

  // scroll(position, progress, direction, speed) {
  //   this._story.scene.scroll(position, progress, direction, speed);
  // }

  // ---------------------------------------------------------------------------

  _loader({ ft }) {}
  _update({ ft }) {
    return {
      random: -1.0 + Math.random() * 2.0,
    };
  }
}
