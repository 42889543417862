import { RingbaEvents } from '~/webgl';
import { Object3D, Group } from 'three';

// --

export class RingbaObject extends Object3D {
  // ---------------------------------------------------------------------------

  constructor(properties = {}) {
    super();
    RingbaEvents(this);

    // --
    this._create(properties);
  }

  _create(properties) {
    /* virtual */
  }

  // --

  _add(object, container = this) {
    container.add(object);
    return object;
  }

  _container(object) {
    const container = new Group();
    object && this._add(object, container);

    return container;
  }

  // --

  _design(model, design = {}) {
    this.position.copy(model.position);

    this._model = this._add(model);
    this._model.position.set(0.0, 0.0, 0.0);

    this._construct(this._model, design);
  }

  _construct(model, design) {
    Object.entries(design).forEach((part) => {
      if (typeof part[1] === 'string') this[part[0]] = model.getObjectByName(part[1]);
      else this[part[0]] = part[1][1](model.getObjectByName(part[1][0]));
    });
  }

  // --

  destroy() {
    this.off();
    this._destroy();
  }

  _destroy() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  resize(width, height, properties = {}) {
    this._resize(width, height, properties);
  }

  _resize(width, height, properties) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  show(properties = {}) {
    if (this._visible) return;

    this._visible = true;
    this._show(properties);
  }

  hide(properties = {}) {
    if (typeof this._visible !== 'undefined' && !this._visible) return;

    this._visible = false;
    this._hide(properties);
  }

  _show(properties) {
    /* virtual */
    this.visible = true;
  }

  _hide(properties) {
    /* virtual */
    this.visible = false;
  }

  // ---------------------------------------------------------------------------

  update(time, properties) {
    this._update(time, properties);
  }

  _update(time, properties) {
    /* virtual */
  }
}
