<script setup>
  import Ringba from './core/ringba';

  // --

  const { loaderProgress, loaderComplete } = useLoader();
  const DOMLock = useDOMLock();

  // --

  let ringba;
  const ringbaEl = ref();

  const testVideo = ref();

  onMounted(() => {
    ringba = new Ringba(ringbaEl.value, {
      path: '', //useBaseStore().getCdnBaseUrl + '/static',
      isTouch: useDevice().isMobileOrTablet,
      testVideo: testVideo.value,

      onLoadProgress: (progress) => {
        loaderProgress.value = progress;
      },
      onLoadComplete: () => {
        loaderComplete.value = true;
      },

      onCursorDown: () => {
        DOMLock.value = true;
      },

      onCursorUp: () => {
        DOMLock.value = false;
      },
    });
  });

  // --

  // const scrollPosition = inject('scrollPosition');
  // const scrollProgress = inject('scrollProgress');
  // const scrollDirection = inject('scrollDirection');
  // const scrollSpeed = inject('scrollSpeed');

  onResize((width, height, pixelRatio) => {
    ringba.resize(width, height, pixelRatio);
  });

  // watch(scrollPosition, (position) => {
  //   ringba.scroll(position, scrollProgress.value, scrollDirection.value, scrollSpeed.value);
  // });

  // --

  onTick((dt) => {
    ringba.update(dt);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <video ref="testVideo" muted loop playsinline v-show="false">
    <source src="~public/textures/video/test_video.mp4" type="video/mp4" />
  </video>
  <div ref="ringbaEl" class="ringba" :class="DOMLock"></div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .ringba {
    position: fixed;

    top: 0;
    left: 0;

    z-index: $layer-background;

    &.DOMLock {
      cursor: none;
    }
  }
</style>
