import RingbaGL, { RingbaScene, RingbaCamera, state } from '~/webgl';

/* TODO NT / DH: clean this up */
import { Mesh, PointLight, MeshPhongMaterial, BoxGeometry, MeshBasicMaterial, Color, Vector3 } from 'three';

import RingbaPhone from '../objects/ringba-phone';
import RingbaPhoneEffect from '../effects/ringba-phone-effect';

// --

export default class RingbaPhoneScene extends RingbaScene {
  // ---------------------------------------------------------------------------

  _setupCamera(properties) {
    const camera = this._add(new RingbaCamera(0.1, 100.0, { fov: 50.0 }));
    camera.position.z = 30.0;

    this._cameraContainer = this._add(this._container(camera));

    return camera;
  }

  _setupEffects(properties) {
    return [(this._effect = new RingbaPhoneEffect())];
  }

  // WIP
  // _setupSceneEffects() {
  //   /* virtual */
  //   return [];
  // }

  _create(properties) {
    const { isTouch } = properties;

    this._isTouch = isTouch;
    this._touch = RingbaGL().touch;

    // this._scrollPosition = 0.0;
    // this._scrollProgress = 0.0;
    // this._scrollDirection = 1.0;
    // this._scrollSpeed = 0.0;

    this.background = new Color(0x111111);

    // this._cube.position.z = -5.0;

    this._phone = this._add(new RingbaPhone(properties));
  }

  setup(model) {
    this._phone.setup(model);
  }

  // ---------------------------------------------------------------------------

  _resize(width, height) {
    // this._updateFrame();
    this._resizeScene(width, height);
  }

  // _updateFrame() {
  //   const frame = this._camera.getFrame(new Vector3(this._camera.position.x, this._camera.position.y, 0.0));

  //   this._frameWidth = frame.width * 0.5;
  //   this._frameHeight = frame.height * 0.5;
  // }

  _resizeScene(width, height) {
    /* virtual */
  }

  // scroll(position, progress, direction, speed) {
  //   this._scrollPosition = position;
  //   this._scrollProgress = progress;
  //   this._scrollDirection = direction;
  //   this._scrollSpeed = speed;
  // }

  // ---------------------------------------------------------------------------

  _update({ et }, { random }) {
    this._phone.update({ et });

    // TODO: test first shader passes
    // composer setup in place (ringba-gl-scene)

    // this._effect.update(time, {
    //   random,
    //   // scrollSpeed: this._scrollSpeed,
    //   // scrollDirection: this._scrollDirection,
    // });
    // this._updateScene(time, {
    //   random,
    // });
  }
}
