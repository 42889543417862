import { default as _91_46_46_46page_93bbvW8s9pnrMeta } from "/vercel/path0/nuxt/pages/[...page].vue?macro=true";
import { default as bloguFxVJiOs2nMeta } from "/vercel/path0/nuxt/pages/blog.vue?macro=true";
import { default as indexWQikkBuVICMeta } from "/vercel/path0/nuxt/pages/index.vue?macro=true";
import { default as pricingcapVsabKoQMeta } from "/vercel/path0/nuxt/pages/pricing.vue?macro=true";
import { default as productsf11XdTDRQgMeta } from "/vercel/path0/nuxt/pages/products.vue?macro=true";
import { default as termsxYRSDMxZjfMeta } from "/vercel/path0/nuxt/pages/terms.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93bbvW8s9pnrMeta?.name ?? "page",
    path: _91_46_46_46page_93bbvW8s9pnrMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93bbvW8s9pnrMeta || {},
    alias: _91_46_46_46page_93bbvW8s9pnrMeta?.alias || [],
    redirect: _91_46_46_46page_93bbvW8s9pnrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: bloguFxVJiOs2nMeta?.name ?? "blog",
    path: bloguFxVJiOs2nMeta?.path ?? "/blog",
    meta: bloguFxVJiOs2nMeta || {},
    alias: bloguFxVJiOs2nMeta?.alias || [],
    redirect: bloguFxVJiOs2nMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: indexWQikkBuVICMeta?.name ?? "index",
    path: indexWQikkBuVICMeta?.path ?? "/",
    meta: indexWQikkBuVICMeta || {},
    alias: indexWQikkBuVICMeta?.alias || [],
    redirect: indexWQikkBuVICMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pricingcapVsabKoQMeta?.name ?? "pricing",
    path: pricingcapVsabKoQMeta?.path ?? "/pricing",
    meta: pricingcapVsabKoQMeta || {},
    alias: pricingcapVsabKoQMeta?.alias || [],
    redirect: pricingcapVsabKoQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: productsf11XdTDRQgMeta?.name ?? "products",
    path: productsf11XdTDRQgMeta?.path ?? "/products",
    meta: productsf11XdTDRQgMeta || {},
    alias: productsf11XdTDRQgMeta?.alias || [],
    redirect: productsf11XdTDRQgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/products.vue").then(m => m.default || m)
  },
  {
    name: termsxYRSDMxZjfMeta?.name ?? "terms",
    path: termsxYRSDMxZjfMeta?.path ?? "/terms",
    meta: termsxYRSDMxZjfMeta || {},
    alias: termsxYRSDMxZjfMeta?.alias || [],
    redirect: termsxYRSDMxZjfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/terms.vue").then(m => m.default || m)
  }
]