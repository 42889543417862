<script setup>
  const { from, to, gutter } = defineProps({
    from: { type: Number, default: 1 },
    to: { type: Number },
    gutter: { type: Boolean },
  });

  const FROM = from;
  const TO = to === undefined ? from : to;

  const _from = ref(FROM);
  const _to = ref(TO);

  onResize((width) => {
    let columns = 24;
    if (width <= 1800.0) {
      columns = 20;
      if (width <= 1280.0) {
        columns = 16;
        if (width <= 1024.0) {
          columns = 12;
        }
      }
    }

    _from.value = FROM < 0 ? columns + FROM : FROM;
    _to.value = TO < 0 ? columns + TO + 1 : TO + 1;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div :style="{ 'grid-column': `${_from} / ${_to}` }" :class="{ gutter }">
    <slot />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    &.gutter {
      @include break-min-medium {
        margin-left: -$gutter;
      }
    }
  }
</style>
