<script setup>
  const { error } = defineProps({ error: {} });
  const { statusCode, message } = error;

  const block =
    statusCode === 404
      ? {
          title: 'Page does not exist',
          body: '<p>This page either doesn’t exist anymore, has been moved or is temporarily unavailable. Check your spelling, or go back to home or the previous page.</p>',
          button_title: 'Homepage',
          button_link: '/',
        }
      : {
          title: 'An error occurred',
          body: message,
          button_title: 'Homepage',
          button_link: '/',
        };
</script>

<!----------------------------------------------------------------------------->

<template>
  <Title>Ringba - {{ statusCode }}</Title>

  <h1>{{ statusCode }}</h1>
  <!-- <TextCtaBlock :data="block"></TextCtaBlock> -->
  <!-- TODO display properly formatted error -->
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
