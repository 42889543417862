export class RingbaTime {
  // ---------------------------------------------------------------------------

  constructor() {
    this._FRAME = 1.0 / 60.0;

    this.dt = 0.0;
    this.et = 0.0;
    this.ft = 1.0;
  }

  // ---------------------------------------------------------------------------

  update(dt = this._FRAME) {
    this.dt = dt;
    this.ft = dt / this._FRAME;

    this.et += dt;
  }

  // ---------------------------------------------------------------------------

  set(et) {
    this.et = et;
  }
}
