<template>
  <svg width="57" height="32" viewBox="0 0 57 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.4402 7.40939C15.4364 6.41754 16.8995 24.7472 23.9831 24.7472C33.0326 24.7472 36.1235 8.16437 43.4921 8.16437C49.271 8.16437 50.0639 16.4577 55.7301 12.3775C55.7301 12.3775 56.0936 12.1001 56.3444 12.3915C56.6168 12.7094 56.2215 13.0578 56.2215 13.0578C49.9499 17.3647 48.7047 9.37284 43.4921 9.37284C37.0685 9.37284 33.7129 26.7867 24.0033 26.7867C14.2938 26.7867 13.4818 8.1175 0.534485 8.46332L1.4402 7.40939Z"
      fill="url(#paint0_linear_0_1)"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M22.8432 26.6841C23.2169 26.7499 23.602 26.7867 24.0036 26.7867C24.7383 26.7867 25.4375 26.6853 26.1038 26.5004C25.5047 26.4396 24.9777 25.3895 24.8345 24.6953C24.5571 24.7282 24.2734 24.7459 23.9833 24.7459C23.4183 24.7459 22.8888 24.6281 22.3872 24.4141C22.8432 24.9284 23.298 26.247 22.8432 26.6828V26.6841Z"
        fill="#1CB0E7"
      />
    </g>
    <path
      d="M0.533382 7.40939C25.5147 -0.363317 21.6423 30.673 29.491 30.673C37.3397 30.673 33.5712 0.10791 43.282 0.10791C50.9483 0.10791 48.3033 16.0207 55.8227 12.3231C56.2623 12.0951 56.8272 12.7348 55.753 13.2642C47.3039 17.2228 48.1311 2.61605 43.282 2.61605C36.8862 2.61605 38.799 31.8916 29.491 31.8916C20.183 31.8916 23.9655 5.56755 0.928604 8.41898C0.2547 8.56212 0.0798898 8.34171 0.0190863 8.08583C-0.0417171 7.82994 0.0254199 7.5804 0.533382 7.40939Z"
      fill="url(#paint1_linear_0_1)"
    />
    <path
      style="mix-blend-mode: multiply"
      d="M2.42847 6.88111C1.81537 7.03185 1.18454 7.20666 0.533432 7.40934C0.0254703 7.58161 -0.0429335 7.82863 0.0191367 8.08578C0.0799401 8.34166 0.25475 8.56207 0.928655 8.41893C23.9656 5.5675 20.0551 31.8903 29.4911 31.8903C20.2667 31.8903 23.7983 5.39522 2.42847 6.87984V6.88111Z"
      fill="url(#paint2_linear_0_1)"
    />
    <path
      style="mix-blend-mode: overlay"
      d="M27.9152 30.1828C22.5303 26.5751 23.4436 4.25643 5.64087 6.29208C23.2283 4.58198 22.396 26.8867 27.9152 30.1828Z"
      fill="url(#paint3_linear_0_1)"
    />
    <path
      style="mix-blend-mode: multiply"
      d="M22.7391 24.5471C17.8204 22.9434 15.5111 12.0621 7.80938 8.49876C5.82566 8.14914 3.55187 8.0934 0.928455 8.41895C0.808115 8.44429 0.704242 8.45822 0.613037 8.46202C1.95958 8.43415 3.17691 8.61276 4.29038 8.95478C15.4769 7.51577 16.8602 25.3464 23.6347 26.5371C23.3333 25.9063 23.0381 25.2387 22.7391 24.5483V24.5471Z"
      fill="url(#paint4_linear_0_1)"
    />
    <path
      style="mix-blend-mode: multiply"
      d="M37.6451 6.32504C34.8292 15.2606 35.1496 30.673 29.4911 30.673C23.8326 30.673 24.2532 15.0769 16.0156 8.76477C15.9092 8.6837 15.8015 8.6039 15.6913 8.52409C24.7206 15.6242 22.8344 31.6256 29.576 31.6256C36.3176 31.6256 35.1268 14.3004 37.7541 5.98682C37.7173 6.09829 37.6806 6.21103 37.6451 6.32377V6.32504Z"
      fill="url(#paint5_linear_0_1)"
    />
    <path
      style="mix-blend-mode: multiply"
      d="M43.1946 0.656433C39.1145 0.656433 38.7687 10.1063 37.4741 17.2748C37.4804 17.2456 37.4855 17.2178 37.4918 17.1899C38.9701 9.89222 40.0937 2.61608 43.2821 2.61608C47.7904 2.61608 47.3939 15.4076 54.1038 13.8508C54.1545 13.8394 54.2039 13.828 54.2558 13.8153C54.822 13.1667 55.1666 12.7589 55.1666 12.7589C46.5578 16.6047 48.786 0.6577 43.1959 0.6577L43.1946 0.656433Z"
      fill="url(#paint6_linear_0_1)"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M56.237 12.9146C56.1432 12.5105 55.9811 12.2407 55.8227 12.3231C55.7797 12.3446 55.7379 12.3623 55.6948 12.3826C52.4038 14.6488 50.1009 13.1224 48.7239 11.0652C50.1224 13.4036 52.0441 15.0009 55.7543 13.263C55.9861 13.149 56.142 13.0286 56.2382 12.9146H56.237Z"
        fill="#DAA300"
      />
    </g>
    <path
      style="mix-blend-mode: overlay"
      d="M55.8225 12.3231C48.3031 16.0207 50.9481 0.10791 43.2818 0.10791C42.2722 0.10791 41.4159 0.45373 40.671 1.04656C41.3614 0.563936 42.148 0.296654 43.055 0.296654C50.7226 0.296654 48.0776 16.2094 55.5957 12.5118C55.9454 12.3294 56.3621 12.7006 55.9783 13.1262C56.7155 12.6309 56.224 12.1141 55.8225 12.3231Z"
      fill="url(#paint7_linear_0_1)"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M35.6715 15.7761C35.4954 16.8706 35.3219 17.9625 35.1432 19.0291C35.7411 17.1645 37.1928 14.5664 38.0884 14.199C38.2708 13.2819 38.4532 12.3825 38.6407 11.5148C37.6476 12.8386 36.6747 14.3156 35.6727 15.7749L35.6715 15.7761Z"
        fill="#DAA300"
      />
    </g>
    <path
      d="M56.3445 12.3914C56.0949 12.1001 55.7301 12.3775 55.7301 12.3775C50.064 16.4576 49.2697 8.16431 43.4922 8.16431C38.9851 8.16431 36.0767 14.3663 32.4285 19.1849V21.6741C36.5112 16.5362 39.3715 9.37404 43.4922 9.37404C48.706 9.37404 49.9513 17.3659 56.2216 13.059C56.2216 13.059 56.6168 12.7119 56.3445 12.3927V12.3914Z"
      fill="url(#paint8_linear_0_1)"
    />
    <path
      style="mix-blend-mode: multiply"
      d="M25.3929 26.4839C29.5947 25.5503 32.6678 19.1077 35.9246 14.2244C35.9284 14.2041 35.9309 14.1839 35.9347 14.1649C32.7717 18.6998 29.6441 24.2253 24.6912 24.7117C24.923 25.3362 25.1548 25.9303 25.3929 26.4851V26.4839Z"
      fill="url(#paint9_linear_0_1)"
    />
    <path
      style="mix-blend-mode: multiply"
      d="M36.8457 15.2048C38.9662 11.9949 40.9981 9.37274 43.4923 9.37274C48.4655 9.37274 49.8285 16.7591 55.3882 13.5834C49.712 16.7946 47.7802 8.31122 43.6646 8.31122C41.71 8.31122 39.7402 10.4925 36.847 15.2061L36.8457 15.2048Z"
      fill="url(#paint10_linear_0_1)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="0.534485"
        y1="17.0784"
        x2="56.4394"
        y2="17.0784"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15" stop-color="#0046D3" />
        <stop offset="0.59" stop-color="#0093FF" />
        <stop offset="1" stop-color="#0068CA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_0_1"
        x1="8.52667e-05"
        y1="15.9991"
        x2="56.3699"
        y2="15.9991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#008915" />
        <stop offset="0.59" stop-color="#1DFC4D" />
        <stop offset="1" stop-color="#008D07" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_0_1"
        x1="-10.2604"
        y1="19.3559"
        x2="19.2305"
        y2="19.3559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0F6FF" />
        <stop offset="0.5" stop-color="#27CEB5" />
        <stop offset="1" stop-color="#F0F6FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_0_1"
        x1="20.5098"
        y1="12.4028"
        x2="14.8095"
        y2="21.3118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#FFC435" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_0_1"
        x1="0.613037"
        y1="17.3697"
        x2="23.6347"
        y2="17.3697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1C75BC" />
        <stop offset="0.47" stop-color="white" />
        <stop offset="1" stop-color="#72A9D5" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_0_1"
        x1="19.7334"
        y1="18.8075"
        x2="36.3961"
        y2="18.8075"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0F6FF" />
        <stop offset="0.5" stop-color="#27CEB5" />
        <stop offset="1" stop-color="#F0F6FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_0_1"
        x1="37.4741"
        y1="8.96497"
        x2="50.7051"
        y2="8.96497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0F6FF" />
        <stop offset="0.5" stop-color="#27CEB5" />
        <stop offset="1" stop-color="#F0F6FF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_0_1"
        x1="52.667"
        y1="3.10755"
        x2="47.8167"
        y2="7.56393"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#FFC435" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_0_1"
        x1="0.534537"
        y1="14.9186"
        x2="56.4395"
        y2="14.9186"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15" stop-color="#0046D3" />
        <stop offset="0.59" stop-color="#0093FF" />
        <stop offset="1" stop-color="#0068CA" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_0_1"
        x1="24.6912"
        y1="20.3237"
        x2="35.9347"
        y2="20.3237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1C75BC" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_0_1"
        x1="35.4523"
        y1="11.758"
        x2="53.9935"
        y2="11.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.46" stop-color="#0076D4" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(57);
    height: px(32);
  }
</style>
