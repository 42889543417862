<template>
  <Grid>
    <span /><span /><span /><span /> <span /><span /><span /><span /> <span /><span /><span /><span />
    <span /><span /><span /><span /> <span /><span /><span /><span /> <span /><span /><span /><span />
  </Grid>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .grid {
    position: fixed;
    z-index: $layer-max;

    pointer-events: none;

    span {
      border: 0.5px solid rgba(255, 0, 0, 0.3);
      background-color: rgba(255, 0, 0, 0.02);
      height: 100vh;

      grid-column: auto;

      @include break-max {
        &:nth-child(n + 21) {
          display: none;
        }
      }

      @include break-large {
        &:nth-child(n + 17) {
          display: none;
        }
      }

      @include break-medium {
        &:nth-child(n + 13) {
          display: none;
        }
      }
    }
  }
</style>
