import { PI } from '~/webgl';
import { PerspectiveCamera, OrthographicCamera } from 'three';

// --

export class RingbaCamera {
  // ---------------------------------------------------------------------------

  constructor(near = 0.1, far = 100.0, properties = {}) {
    const fov = properties.fov;
    fov || (this.frustum = properties.frustum || 1.0);

    const camera = fov
      ? new PerspectiveCamera(properties.fov, window.innerWidth / window.innerHeight, near, far)
      : new OrthographicCamera(
          -this.frustum * 0.5,
          this.frustum * 0.5,
          this.frustum * 0.5,
          -this.frustum * 0.5,
          near,
          far,
        );

    camera.resize = fov ? this.__perspective : this.__orthographic;
    camera.getFrame = fov ? this.__perspectiveFrame : this.__orthographicFrame;
    camera.update = this.update;

    // --
    this._create(properties);
    this._construct(camera);

    return camera;
  }

  _construct(camera) {
    [...Object.getOwnPropertyNames(this), ...Object.getOwnPropertyNames(Object.getPrototypeOf(this))].forEach(
      (property) => (camera[property] = this[property]),
    );
  }

  _create(properties) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  __perspective(width, height) {
    this.aspect = width / height;
    this.updateProjectionMatrix();
  }

  __orthographic(width, height) {
    this._aspect = width / height;

    this.left = -this.frustum * this._aspect * 0.5;
    this.right = this.frustum * this._aspect * 0.5;
    this.top = this.frustum * 0.5;
    this.bottom = -this.frustum * 0.5;

    this.updateProjectionMatrix();
  }

  // ---------------------------------------------------------------------------

  __perspectiveFrame(location) {
    const fov = (this.fov * PI) / 180.0;

    const height = 2.0 * Math.tan(fov * 0.5) * this.position.distanceTo(location);
    const width = height * this.aspect;

    return { width, height };
  }

  __orthographicFrame() {
    return { width: this.frustum * this._aspect, height: this.frustum };
  }

  // ---------------------------------------------------------------------------

  update(time, properties) {
    this._update(time, properties);
  }

  _update(time, properties) {
    /* virtual */
  }
}
