export function state(object, name, states) {
  // ---------------------------------------------------------------------------

  Object.values(states).forEach((state) => {
    state.set = (id) => {
      states[id] && (object[name] = states[id]);
    };
  });

  object[name] = Object.values(states)[0];
}
