export default class RingbaScroll {
  // ---------------------------------------------------------------------------

  constructor() {
    /*
     * Smooth scroll with storytelling across pages for Ringba v2.0
     * Supports touch and drag interaction
     *
     * TODO NT: test on latest iOS
     * TODO DH: end of page bug?
     */

    const slurp = 1.17;
    const limit = 120.0;

    const wheel = 1.0;
    const touch = 1.0;
    const slide = 0.0;

    this._NAME = 'ringba-scroll';
    this._AXIS = 'Y';

    this._container = window;
    this._content = document.body;

    this._ease = (t) => 1.0 - Math.pow(2.0, -10.0 * t);

    this._SLURP = slurp;
    this._LIMIT = limit;

    this._WHEEL = wheel;
    this._TOUCH = touch * 2.4;
    this._SLIDE = slide * 2.25;

    this._fill();
  }

  // ---------------------------------------------------------------------------

  refill() {
    this.___refill();
  }

  ___refill = () => {
    const bottom = Math.max(1.0, this._$bottom());

    if (this._bottom === bottom) return;

    this._bottom = bottom;
    this._straw = this._$straw();

    this.__pageScroll();
  };

  // --

  ___pageScroll = () => {
    this._pageScroll();
  };

  __pageScroll() {
    this._to = this._surface = this._$surface();
    this._progress = this._surface / this._bottom;
  }

  // --

  _$surfaceX = () => this._container.scrollLeft;
  _$surfaceY = () => this._container.scrollTop;
  _$surfaceXW = () => this._container.scrollX;
  _$surfaceYW = () => this._container.scrollY;

  _$bottomX = () => this._content.scrollWidth - this._container.offsetWidth;
  _$bottomY = () => this._content.scrollHeight - this._container.offsetHeight;
  _$bottomXW = () => this._content.scrollWidth - this._container.innerWidth;
  _$bottomYW = () => this._content.scrollHeight - this._container.innerHeight;

  _$strawX = () => this._container.offsetWidth / this._content.scrollWidth;
  _$strawY = () => this._container.offsetHeight / this._content.scrollHeight;
  _$strawXW = () => this._container.innerWidth / this._content.scrollWidth;
  _$strawYW = () => this._container.innerHeight / this._content.scrollHeight;

  // ---------------------------------------------------------------------------

  ___wheel = (e) => {
    e.preventDefault();
    this._slurp(this._WHEEL * this._$wheel(e)) && e.stopPropagation();
  };

  _$wheelX = ({ deltaX, deltaY }) => (Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : deltaX);
  _$wheelY = ({ deltaY }) => deltaY;

  // --

  ___touchstart = (e) => {
    this._x = e.targetTouches[0].clientX;
    this._y = e.targetTouches[0].clientY;
  };

  ___touchmove = (e) => {
    e.preventDefault();

    this._slurp(this._TOUCH * -this._$touch(e.targetTouches[0])) && e.stopPropagation();

    this._x = e.targetTouches[0].clientX;
    this._y = e.targetTouches[0].clientY;
  };

  _$touchX = ({ clientX, clientY }) => {
    const deltaX = clientX - this._x;
    const deltaY = clientY - this._y;

    return Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : deltaX;
  };

  _$touchY = ({ clientY }) => clientY - this._y;

  // --

  ___mousedown = (e) => {
    this._lock || e.stopPropagation();
    this._slide = true;

    this._x = e.clientX;
    this._y = e.clientY;
  };

  ___mousemove = (e) => {
    if (!this._slide) return;

    e.preventDefault();
    this._slurp(this._SLIDE * -this._$touch(e)) && e.stopPropagation();

    this._x = e.clientX;
    this._y = e.clientY;
  };

  ___mouseup = (e) => {
    this._slide = false;
  };

  // ---------------------------------------------------------------------------

  __slurp(volume) {
    this._start();

    this._time = 0.0;
    this._from = this._surface;
    this._to = Math.min(Math.max(0.0, this._to + Math.min(Math.max(-this._LIMIT, volume), this._LIMIT)), this._bottom);

    return this._to > 0.0 && this._to < this._bottom;
  }

  __scroll = (interval) => {
    this._time += interval;

    if (this._time < this._SLURP)
      this._surface = this._from + this._ease(this._time / this._SLURP) * (this._to - this._from);
    else {
      this._surface = this._to;
      this._finish();
    }

    this._progress = this._surface / this._bottom;
    this._$scroll(this._surface);
  };

  _$scrollX = (x) => (this._container.scrollLeft = x);
  _$scrollY = (y) => (this._container.scrollTop = y);
  _$scrollXW = (x) => this._container.scrollTo(x, 0.0);
  _$scrollYW = (y) => this._container.scrollTo(0.0, y);

  // ---------------------------------------------------------------------------

  __start = () => {
    if (this._lock) return;

    this._start = this.__reset;
    this._pageScroll = this.__reset;

    this.scroll = this.__scroll;

    this._smoothie.classList.add('scrolling');
  };

  _finish = () => {
    this.scroll = this.__reset;

    this._start = this.__start;
    this._pageScroll = this.__pageScroll;

    this._smoothie.classList.remove('scrolling');
  };

  // --

  unlock() {
    this._lock = false;
    this._slurp = this.__slurp;
  }

  lock() {
    this._lock = true;

    this._slurp = this.__reset;
    this._finish();
  }

  // ---------------------------------------------------------------------------

  get progress() {
    return this._progress;
  }

  get straw() {
    return this._straw;
  }

  // ---------------------------------------------------------------------------

  _fill() {
    this._smoothie = this._container === window ? document.body : this._container;

    this._smoothie.classList.add(this._NAME);

    // --

    this._slurp = this.__slurp;
    this._start = this.__start;
    this._pageScroll = this.__pageScroll;

    this.scroll = this.__reset;

    // --
    const W = this._container === window ? 'W' : '';

    this._$bottom = this[`_$bottom${this._AXIS}${W}`];
    this._$straw = this[`_$straw${this._AXIS}${W}`];
    this._$surface = this[`_$surface${this._AXIS}${W}`];
    this._$scroll = this[`_$scroll${this._AXIS}${W}`];

    this._$wheel = this[`_$wheel${this._AXIS}`];
    this._$touch = this[`_$touch${this._AXIS}`];

    // --
    this._refill = new ResizeObserver(this.___refill);

    if (this._container === window) {
      this._container.addEventListener('resize', this.___refill);
      this._container.addEventListener('orientationchange', this.___refill);

      this.___refill();
    } else this._refill.observe(this._container);

    this._refill.observe(this._content);

    // --
    this._container.addEventListener('scroll', this.___pageScroll);

    // --
    if (this._WHEEL) {
      this._container.addEventListener('wheel', this.___wheel, {
        passive: false,
      });
    }

    if (this._TOUCH) {
      this._container.addEventListener('touchstart', this.___touchstart);
      this._container.addEventListener('touchmove', this.___touchmove, {
        passive: false,
      });
    }

    if (this._SLIDE) {
      this._container.addEventListener('mousedown', this.___mousedown);
      document.addEventListener('mousemove', this.___mousemove);
      document.addEventListener('mouseup', this.___mouseup);
    }
  }

  reset() {
    this._smoothie.classList.remove(this._NAME, 'scrolling');

    this._container.removeEventListener('resize', this.___refill);
    this._container.removeEventListener('orientationchange', this.___refill);

    this._refill.disconnect();

    this._container.removeEventListener('scroll', this.___pageScroll);
    this._container.removeEventListener('wheel', this.___wheel);

    this._container.removeEventListener('touchstart', this.___touchstart);
    this._container.removeEventListener('touchmove', this.___touchmove);

    this._container.removeEventListener('mousedown', this.___mousedown);
    document.removeEventListener('mousemove', this.___mousemove);
    document.removeEventListener('mouseup', this.___mouseup);
  }

  __reset() {}
}
