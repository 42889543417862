import { RingbaScene } from '~/webgl';

// --

export class RingbaStory {
  // ---------------------------------------------------------------------------

  constructor(scenes = [new RingbaScene()]) {
    this._scenes = scenes;
    this._scenes.forEach((scene, index) => {
      scene.index = index;
    });

    this._scene = scenes[0];
  }

  // --

  destroy() {
    this._scenes.forEach((scene) => {
      scene.destroy();
    });
  }

  // ---------------------------------------------------------------------------

  resize(width, height, properties) {
    this._scenes.forEach((scene) => {
      scene.resize(width, height, properties);
    });
  }

  prerender() {
    this._scenes.forEach((scene) => {
      scene.render(0.0);
    });
  }

  action(action) {
    this._scenes.forEach((scene) => {
      action(scene, scene.index);
    });
  }

  // ---------------------------------------------------------------------------

  to(index, properties) {
    const scene = this._scenes[index];

    // @NT is this enough control over globe / community portals?
    // do we need additional states / callbacks?

    if (scene) {
      this._scene.leave(properties);
      this._scene = scene;

      this._scene.enter(properties);
    }
  }

  next(properties, loop = true) {
    const index = this._scene.index + 1;
    this.to(loop ? this._loop(index) : index, properties);
  }

  prev(properties, loop = true) {
    const index = this._scene.index - 1;
    this.to(loop ? this._loop(index) : index, properties);
  }

  // --

  _loop(index) {
    return index < 0 ? this._scenes.length - 1 : index >= this._scenes.length ? 0 : index;
  }

  // ---------------------------------------------------------------------------

  loader(time, properties = {}) {
    this._scene.loader(time, properties);
    this._scene.render(time);
  }

  update(time, properties = {}) {
    this._scene.update(time, properties);
    this._scene.render(time);
  }

  // ---------------------------------------------------------------------------

  get scene() {
    return this._scene;
  }
}
