<script setup>
  const store = useBaseStore();
  // const menu = store.getNavBar;

  /*
   * Placeholders until CMS page and navigation data is available in base store
   */
  const items = {
    primary: [
      { title: 'Home', url: '/', active: true },
      { title: 'Products', url: '/products', count: 9, active: true },
      { title: 'Archetypes', url: '/archetypes', count: 4 },
      { title: 'Community', url: '/community', count: 9 },
      { title: 'Pricing', url: '/pricing', active: true },
      { title: 'Blog', url: '/blog', active: true },
      { title: 'Support', url: '/support' },
    ],
    secondary: [
      { title: 'Call Flows', url: '/call-flows' },
      { title: 'Ring Tree', url: '/ring-tree' },
      { title: 'ICP', url: '/icp' },
      { title: 'Predictive Routing', url: '/predictive-routing' },
      { title: 'Real-time Bidding', url: '/real-time-bidding' },
      { title: 'Call Tracking', url: '/call-tracking' },
      { title: 'Ringba X', url: '/ringba-x' },
      { title: 'Transcription', url: '/transcription' },
      { title: 'AI Voice Bots', url: '/ai-voice-bots' },
    ],
    bottomLeft: [
      { title: 'Terms', url: '/terms' },
      { title: 'Privacy', url: '/privacy' },
      { title: 'Abuse', url: '/abuse' },
    ],
    bottomRight: [
      { title: 'Glossary', url: '/glossary' },
      { title: 'System status', url: '/system-status' },
      { title: 'Developers', url: '/developers' },
    ],
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="menu">
    <Section class="top">
      <GridItem :from="4" :to="16" class="grid-item">
        <div class="columns">
          <div class="column left">
            <nav>
              <ul>
                <li v-for="link in items.primary">
                  <NuxtLink :to="link.url" line="0.1" :disable="false" :class="{ inactive: !link.active }">
                    {{ link.title }}
                    <span class="count" v-if="link.count">[{{ String(link.count).padStart(2, '0') }}]</span>
                  </NuxtLink>
                </li>
              </ul>
            </nav>
          </div>
          <div class="column right disabled">
            <nav>
              <ul>
                <li v-for="link in items.secondary">
                  <NuxtLink :to="link.url" line="0.1" :disable="false">{{ link.title }}</NuxtLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </GridItem>
    </Section>

    <Section class="bottom">
      <GridItem :from="4" :to="16" class="grid-item">
        <div class="columns">
          <div class="column left">
            <h4 class="text-small">Call us</h4>
            <span class="text-large">(800) 824-5000</span>

            <nav>
              <ul>
                <li v-for="link in items.bottomLeft">
                  <NuxtLink :to="link.url" line="0.1" :disable="false">{{ link.title }}</NuxtLink>
                </li>
              </ul>
            </nav>
          </div>

          <div class="column right">
            <h4 class="text-small">Follow us</h4>
            <span class="text-large platforms">
              <a href="">FB</a>
              <a href="">YT</a>
              <a href="">IN</a>
              <a href="">X</a>
            </span>

            <nav>
              <ul>
                <li v-for="link in items.bottomLeft">
                  <NuxtLink :to="link.url" line="0.1" :disable="false">{{ link.title }}</NuxtLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </GridItem>
    </Section>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .grid-item {
    @include break-large {
      @include column(6, 11);
    }
    @include break-medium {
      @include column(4, 9);
    }
    @include break-phone {
      @include column(2, 11);
    }
  }

  .columns {
    width: 100%;
    display: flex;
    gap: px(24);

    @include break-large {
      flex-wrap: wrap;
    }
  }

  .column {
    flex-grow: 1;
    width: 50%;
  }

  .menu {
    position: fixed;
    z-index: $layer-header - 10;
    padding: calc($header-height + px(50)) 0;

    top: 0;

    background: $black;

    @include break-max {
      padding: calc($header-height + px(40)) 0;
    }

    @include break-medium {
      padding: calc($header-height + px(20)) 0;
    }

    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }

  nav {
    li a {
      text-decoration: none;
      color: $off-white;
      opacity: 0.4;
      transition:
        color 0.43s $sineInOut,
        opacity 0.43s $sineInOut;
      cursor: pointer;

      &.inactive {
        pointer-events: none;
        text-decoration: line-through;
      }

      &:hover {
        color: $white;
        opacity: 1;
      }
    }
  }

  .top {
    nav {
      @include title;

      li {
        .count {
          font-size: px(11);
          display: inline-block;
          transform: translate(px(-6), px(-22));
        }
      }
    }
  }

  .disabled {
    pointer-events: none;
    text-decoration: line-through;
  }

  .bottom {
    .text-small {
      font-size: px(11);
    }
    .text-large {
      @include title;
      margin: px(24) 0;
      display: inline-block;
    }

    nav {
      li {
        display: inline-block;
        margin-right: px(18);
        font-size: px(11);
      }
    }

    .platforms {
      a {
        margin-right: px(24);
        color: $white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
