import SmoothScroll from '~/utils/ringba-scroll';

// --

export const scroll = ref('');

// --

export default (config) => {
  onMounted(() => {
    scroll.value = scroll.value || new SmoothScroll();
  });

  onBeforeUnmount(() => {
    scroll.value?.reset();
    scroll.value = null;
  });

  onTick((dt) => {
    scroll.value.scroll(dt);
  });
};
