<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <button><IconDot /><slot /></button>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  button {
    background-color: $white;
    color: $black;

    height: px(50);
    padding: 0 px(12);

    border-radius: px(8);

    display: flex;
    justify-content: center;
    align-items: center;

    gap: px(9);

    white-space: nowrap;

    @include break-medium {
      height: px(46);
    }

    @include break-small {
      height: px(38);
      gap: px(6);

      border-radius: px(6);
    }

    @include break-phone {
      height: px(30);
      gap: px(5);

      padding: 0 px(8);

      border-radius: px(4);
    }
  }
</style>
