<script setup>
  import { scroll } from '~/composables/ringba/useSmoothScroll';

  // --

  useFontScale();

  useTouchDevice();
  useSmoothScroll();

  onServerPrefetch(async () => {
    const baseStore = useBaseStore();
    await baseStore.fetchLayout();
  });

  // --

  const { loaderFinished } = useLoader();

  // --

  const scrollProgress = ref(0.0);
  provide('scrollProgress', scrollProgress);

  onTick(() => {
    scrollProgress.value = scroll.value.progress;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Ringba />
  <Fonts />

  <TestGrid />

  <Body>
    <Loader v-if="!loaderFinished" @finished="loaderFinished = true" />
    <Grid>
      <Header />
      <GridItem :from="3" gutter class="scroll-progress"><ScrollProgress /></GridItem>
      <main>
        <slot />
      </main>
    </Grid>
    <Ringba />
  </Body>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .scroll-progress {
    @include break-medium {
      display: none;
    }
  }

  main {
    min-height: 100vh;
    padding: px(184) 0 px(280);
  }
</style>
