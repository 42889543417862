import { RingbaEffect } from '~/webgl';

// TODO NT: transitions
import gsap from 'gsap';

import RingbaVert from '../shaders/ringba.vert';
import RingbaFrag from '../shaders/ringba.frag';

// --

export default class RingbaPhoneEffect extends RingbaEffect {
  // ---------------------------------------------------------------------------

  constructor() {
    super('RingbaPhoneEffect', RingbaVert, RingbaFrag, {
      _time: { value: 0.0 },
      _random: { value: 0.0 },
    });
  }

  // ---------------------------------------------------------------------------

  _update({ et }, { random }) {
    this._uniform('_time', et * 0.03);
    this._uniform('_random', random);
  }

  // ...
  // ...
}
