export class Cache {
  // ---------------------------------------------------------------------------

  constructor() {
    this._storage = [];
  }

  // --

  store(id, data) {
    const index = this._storage.findIndex((item) => item.id === id);

    if (index !== -1) this._storage[index].data = data;
    else this._storage.push({ id, data });
  }

  retrieve(id) {
    const item = this._storage.find((item) => item.id === id);
    if (item) return item.data;
  }

  remove(id) {
    const index = this._storage.findIndex((item) => item.id === id);
    if (index !== -1) return this._storage.splice(index, 1)[0].data;
  }

  // --

  clear() {
    this._storage = [];
  }
}
