import RingbaGL, { RingbaEvents } from '~/webgl';

// --

export class RingbaModule {
  // ---------------------------------------------------------------------------

  constructor(id) {
    RingbaEvents(this);
    this._id = id;
  }

  // ---------------------------------------------------------------------------

  resize(width, height) {
    this._visibleWidth = RingbaGL().visibleWidth;
    this._visibleHeight = RingbaGL().visibleHeight;

    this._resize((this._width = width), (this._height = height));
  }

  _resize(width, height) {
    /* virtual */
  }

  // --

  destroy() {
    this.off();
    this._destroy();
  }

  _destroy() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  get id() {
    return this._id;
  }
}
