import { DefaultVert, DefaultFrag } from '~/webgl';

import { ShaderMaterial } from 'three';
import { Pass } from 'postprocessing';

// --

export class RingbaEffect extends Pass {
  // ---------------------------------------------------------------------------

  constructor(name, vert, frag, uniforms) {
    super(name);

    // --
    this._material = new ShaderMaterial({
      vertexShader: vert || DefaultVert,
      fragmentShader: frag || DefaultFrag,

      uniforms: { ...uniforms, _input: { value: null } },
    });

    // TODO: @NT - test double buffer setup
    // TODO: @DH - please fix shader priority

    this.fullscreenMaterial = this._material;
    this._output = null;
  }

  // --

  destroy() {
    this._material.dispose();
    this._destroy();
  }

  _destroy() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  setSize(width, height) {
    this._resize((this._width = width), (this._height = height));
  }

  _resize(width, height) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  update(time, properties = {}) {
    this._update(time, properties);
  }

  _update(time, properties) {
    /* virtual */
  }

  render(renderer, inputBuffer, outputBuffer /*, deltaTime, stencilTest */) {
    this._material.uniforms._input.value = inputBuffer.texture;

    renderer.setRenderTarget(this.renderToScreen ? this._output : outputBuffer);
    renderer.render(this.scene, this.camera);
  }

  // ---------------------------------------------------------------------------

  _uniform(name, value) {
    this._material.uniforms[name].value = value;
  }

  // --

  set output(output) {
    this._output = output;
  }
}
