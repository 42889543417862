export function RingbaEvents(transmitter) {
  // ---------------------------------------------------------------------------

  transmitter.__radio = {};

  transmitter.on = __on;
  transmitter.once = __once;
  transmitter.off = __off;

  transmitter.transmit = __transmit;
  transmitter.transmits = __transmits;
}

// -----------------------------------------------------------------------------

function __on(signal, receiver) {
  this.__radio[signal] || (this.__radio[signal] = []);
  this.__radio[signal].includes(receiver) || this.__radio[signal].push(receiver);
}

function __once(signal, receiver) {
  const receive = (properties) => {
    this.off(signal, receive);
    receiver(properties);
  };
  this.on(signal, receive);
}

function __off(signal, receiver) {
  if (signal) {
    if (receiver) {
      if (this.__radio[signal]) {
        const index = this.__radio[signal].indexOf(receiver);
        index !== -1 && this.__radio[signal].splice(index, 1);
      }
    } else delete this.__radio[signal];
  } else this.__radio = {};
}

// --

function __transmit(signal, properties = {}) {
  if (this.__radio[signal]) {
    this.__radio[signal].forEach((receiver) => receiver({ signal, ...properties }));
  }
}

function __transmits(signal, receiver) {
  return !!(this.__radio[signal] && (!receiver || this.__radio[signal].includes(receiver)));
}
