import { RingbaGLConfig, RingbaCamera /* temp. moved to scene */ } from '~/webgl';

import RingbaPhoneScene from './scenes/ringba-phone-scene';
import { ColorManagement } from 'three';

// --

export default class RingbaConfig extends RingbaGLConfig {
  // ---------------------------------------------------------------------------

  _setupRingba(WORLD, properties) {
    ColorManagement.enabled = false;

    return {
      ...super._setupRingba(WORLD, properties),

      powerPreference: 'high-performance',
      antialias: false, // TODO: implement AA shader

      // minAspectRatio: 1.0,
      maxAspectRatio: 2.0,
    };
  }

  _setupRenderer(WORLD, renderer, properties) {
    /* virtual */
    // @DH - please configure renderer for ThreeJS r166
  }

  _setupModules(WORLD, properties) {
    return [...super._setupModules(WORLD, properties)];
  }

  _setupStory(WORLD, properties) {
    return [new RingbaPhoneScene(properties) /* TODO add additional scenes — globe, grid, etc. */];
  }
}
