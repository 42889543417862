import RingbaGL, { RingbaObject, state } from '~/webgl';
import {
  MeshBasicMaterial,
  MeshPhongMaterial,
  PointLight,
  MeshMatcapMaterial,
  DoubleSide,
  NearestFilter,
  Quaternion,
  VideoTexture,
} from 'three';

import gsap from 'gsap';

// --

export default class RingbaPhone extends RingbaObject {
  // ---------------------------------------------------------------------------

  _create({ testVideo }) {
    // WIP
    // testing materials with Artem

    // 29/05 - working version _v5 (!)
    // **later updates not yet tested in mobile browsers

    // RingbaObject design and constructs tested with model
    // destroy() untested
    // DRACO compression v1.4.1 optimized (see load module)

    // --
    // this._active = false;
    // this._activation = 0.0;

    // this._power = 0.0;
    // this._flames = 0.0;

    // this._up = true;

    // this._altitude = this._ALTITUDE = 31.2;
    // this._lift = 0.0;
    this._testVideo = testVideo;

    // this._shadowStrength = 1.0;
    this._MATERIALS = {
      body: this._material('textures/materials/phone_body.png'),
      details: this._material('textures/materials/phone_details.png'),
      glass: this._material('textures/materials/phone_glass.png'),
    };
  }

  setup(model) {
    // --
    this._design(model, {
      _body: ['phone_body', (model) => model],
      _details: ['phone_details', (model) => model],
      _glass: ['phone_glass', (model) => model],
      _display: ['phone_display', (model) => model],
      _camera: ['phone_camera', (model) => model],
      _flash: ['phone_flash', (model) => model],
    });

    //   _body: ['phonw_body', (model) => this._add(new MicrowaverModelBody({ model, materials }), this._model)],
    //   _details: ['phone_details', (model) => this._add(new MicrowaverModelDoor({ model, materials }), this._model)],
    //   _glass: ['phone_glass', (model) => this._add(new MicrowaverModelVents({ model, materials }), this._model)],
    //   _display: ['phone_display', (model) => this._add(new MicrowaverModelAntenna({ model, materials }), this._model)],
    //   _camera: ['phone_camera', (model) => this._add(new MicrowaverModelAntenna({ model, materials }), this._model)],
    //   _flash: ['phone_flash', (model) => this._add(new MicrowaverModelAntenna({ model, materials }), this._model)],
    // });

    this._body.material = this._MATERIALS.body;
    this._flash.material = this._MATERIALS.glass;
    this._glass.material = this._MATERIALS.glass;
    this._camera.material = this._MATERIALS.glass;
    this._camera.material.transparent = true;
    this._camera.material.opacity = 0.3;

    const texture = new VideoTexture(this._testVideo);

    // required to align w shader axis
    texture.flipY = false;

    this._testVideo.play();

    this._details.material = this._MATERIALS.details;

    this._display.material = new MeshBasicMaterial({ map: texture });

    // this._flash.material = new MeshPhongMaterial({ color: 0x00ffff });
    // this._details.material = new MeshPhongMaterial({ color: 0xff0000 });
    // this._camera.material = new MeshPhongMaterial({ color: 0xffff00 });
    // this._glass.material = new MeshPhongMaterial({ color: 0x000033 });
    // this._display.visible = false;
    // --
    // this.scale.set(0.1, 0.1, 0.1);
    this._inner = this._add(this._container(this._model));
    this._outer = this._add(this._container(this._inner));

    // this._shadow = this._add(new MicrowaverModelShadow());
    // this._rotation = 0.0;
  }

  _material(source) {
    return new MeshMatcapMaterial({
      matcap: RingbaGL().load.texture(source),
    });
  }

  // ---------------------------------------------------------------------------

  _update({ et }, properties) {
    this._outer.rotation.y = et;
  }
}
