import RingbaGL, { RingbaGLConfig, RingbaTime, state, stable } from '~/webgl';

// --

export class RingbaWorld {
  // ---------------------------------------------------------------------------

  __config() {
    /* virtual -- returns world configuration */
    return RingbaGLConfig;
  }

  constructor(element, properties = {}) {
    new (this.__config())(this, element, properties);

    // --
    state(this, 'update', {
      loader: this.__loader,
      world: this.__update,
    });

    this._time = new RingbaTime();

    // --
    this._create(properties);
    this._ready(properties);
  }

  _create(properties) {
    /* virtual */
  }

  _ready(properties) {
    /* virtual */
    if (RingbaGL().load.total === 0) this._prerender(properties);
  }

  // --

  destroy() {
    this.off();
    this._destroy();

    this._story.destroy();
    RingbaGL().destroy();
  }

  _destroy() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  resize(width, height, pixelRatio) {
    RingbaGL().resize(width, height, pixelRatio);

    this._story.resize(RingbaGL().width, RingbaGL().height);
    this._resize(RingbaGL().width, RingbaGL().height);
  }

  _resize(width, height) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  _loadStart(properties) {
    /* virtual */
  }

  _loadProgress(properties) {
    /* virtual */
  }

  _loadComplete(properties) {
    /* virtual */
    this._prerender(properties);
  }

  // --

  _prerender(properties) {
    this._story.prerender();

    stable(55.0, () => {
      this._time.set(0.0);
      this.update.set('world');

      this._start(properties);
    });
  }

  _start(properties) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  __loader(dt) {
    this._story.loader(this._time, this._loader(this._time));
    this._time.update(dt);
  }

  __update(dt) {
    this._story.update(this._time, this._update(this._time));
    this._time.update(dt);
  }

  _loader(time) {
    /* virtual -- returns loader properties */
  }

  _update(time) {
    /* virtual -- returns update properties */
  }
}
