<script setup>
  function show() {}

  function hide() {}

  defineExpose({ show, hide });
</script>

<!----------------------------------------------------------------------------->

<template>
  <IconLogo />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    @include break-large {
      max-width: px(50);
    }
  }
</style>
