import { RingbaEvents } from '~/webgl';
import { WebGLRenderer } from 'three';

// --

export class RingbaGL {
  // ---------------------------------------------------------------------------

  constructor(element, config = {}) {
    RingbaEvents(this);

    // --
    this._renderer = new WebGLRenderer(config);

    this._canvas = this._renderer.domElement;
    this._canvas.style.position = 'absolute';

    this._element = element;
    this._element.appendChild(this._canvas);

    this._modules = [];

    // --
    this._minAspectRatio = config.minAspectRatio;
    this._maxAspectRatio = config.maxAspectRatio;
  }

  module(module) {
    this._modules.push((this[module.id] = module));
  }

  // --

  destroy() {
    this.off();

    this._modules.forEach((module) => {
      module.destroy();
    });
  }

  // ---------------------------------------------------------------------------

  resize(width, height, pixelRatio) {
    this._width = this._visibleWidth = width;
    this._height = this._visibleHeight = height;

    this._pixelRatio = Math.min(pixelRatio, 2.0);
    this._applyAspectRatio(this._width, this._height);

    // --
    this._renderer.setSize(this._width, this._height);
    this._renderer.setPixelRatio(this._pixelRatio);

    this._modules.forEach((module) => module.resize(this._width, this._height));
  }

  _applyAspectRatio(width, height) {
    const aspectRatio = width / height;

    // --
    if (this._minAspectRatio && aspectRatio < this._minAspectRatio) {
      this._width *= this._minAspectRatio / aspectRatio;
      this._width = Math.round(this._width);
      this._canvas.style.top = 0.0;
      this._canvas.style.left = `-${(this._width - width) * 0.5}px`;
    }
    // --
    else if (this._maxAspectRatio && aspectRatio > this._maxAspectRatio) {
      this._height *= aspectRatio / this._maxAspectRatio;
      this._height = Math.round(this._height);
      this._canvas.style.top = `-${(this._height - height) * 0.5}px`;
      this._canvas.style.left = 0.0;
    }
    // --
    else {
      this._canvas.style.top = 0.0;
      this._canvas.style.left = 0.0;
    }
  }

  // ---------------------------------------------------------------------------

  get element() {
    return this._element;
  }

  get renderer() {
    return this._renderer;
  }

  // --

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get visibleWidth() {
    return this._visibleWidth;
  }

  get visibleHeight() {
    return this._visibleHeight;
  }

  // --

  get pixelRatio() {
    return this._pixelRatio;
  }
}
