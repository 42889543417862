<template>
  <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.17969H23.9722" stroke="white" stroke-miterlimit="10" />
    <path d="M0.0273438 5H23.9995" stroke="white" stroke-miterlimit="10" />
    <path d="M0.0273438 8.82031H23.9995" stroke="white" stroke-miterlimit="10" />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(24);
    height: px(10);
  }
</style>
