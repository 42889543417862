export function stable(framerate, then, min = 30, max = 150) {
  // ---------------------------------------------------------------------------

  let time = Date.now();

  let frame = 0;
  let total = 0;

  requestAnimationFrame(check);

  // --
  function check() {
    const now = Date.now();
    const fps = 1000.0 / (now - time);

    frame = fps >= framerate ? frame + 1 : 0;
    total++;

    time = now;

    if (frame < min && total < max) requestAnimationFrame(check);
    else then();
  }
}
