import { RingbaModule, config } from '~/webgl';
import {
  LoadingManager,
  Texture,
  TextureLoader,
  CubeTexture,
  CubeTextureLoader,
  Audio,
  AudioLoader,
  PositionalAudio,
} from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

// --

export class Load extends RingbaModule {
  // ---------------------------------------------------------------------------

  constructor(renderer, cache, path = '') {
    super('load');

    this._renderer = renderer;
    this._cache = cache;
    this._path = `${path}/`;

    this._itemsStart = 0;
    this._itemsLoaded = 0;
    this._itemsTotal = 0;

    this._progress = 0.0;

    // --
    this._setup();
  }

  _setup() {
    this._manager = new LoadingManager();
    this._manager.onStart = (url, itemsLoaded, itemsTotal) => {
      this._onStart(url, itemsLoaded, itemsTotal);
    };
    this._manager.onProgress = (url, itemsLoaded, itemsTotal) => {
      this._onProgress(url, itemsLoaded, itemsTotal);
    };
    this._manager.onError = (url) => {
      this._onError(url);
    };

    // --
    this._DRACO = new DRACOLoader();
    this._DRACO.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
  }

  // ---------------------------------------------------------------------------

  model(source, then = (model) => {}) {
    const loader = new GLTFLoader(this._manager);

    loader.setDRACOLoader(this._DRACO);
    loader.load(`${this._path}${source}`, (model) => {
      then(model);
    });
  }

  // --

  texture(source, properties = {}, then = (texture) => {}) {
    const image = this._cache.retrieve(source);

    // --
    if (image) {
      const texture = new Texture(image);
      config(texture, properties).needsUpdate = true;

      this._renderer.initTexture(texture);

      then(texture);
      return texture;
    }
    // --
    else {
      return config(
        new TextureLoader(this._manager).load(properties.external ? source : `${this._path}${source}`, (texture) => {
          this._cache.store(source, texture.image);
          this._renderer.initTexture(texture);

          then(texture);
        }),
        properties,
      );
    }
  }

  cubeTexture(source, format, properties = {}, then = (texture) => {}) {
    const images = this._cache.retrieve(source);

    // --
    if (images) {
      const texture = new CubeTexture(images);
      config(texture, properties).needsUpdate = true;

      then(texture);
      return texture;
    }
    // --
    else {
      return config(
        new CubeTextureLoader(this._manager)
          .setPath(`${this._path}${source}/`)
          .load(
            [`px.${format}`, `nx.${format}`, `py.${format}`, `ny.${format}`, `pz.${format}`, `nz.${format}`],
            (texture) => {
              this._cache.store(source, texture.images);
              then(texture);
            },
          ),
        properties,
      );
    }
  }

  // --

  audio(source, listener, position = false, then = (audio) => {}) {
    const audio = new (position ? PositionalAudio : Audio)(listener);
    const buffer = this._cache.retrieve(source);

    // --
    if (buffer) {
      audio.setBuffer(buffer);
      then(audio);
    }
    // --
    else {
      new AudioLoader(this._manager).load(`${this._path}${source}`, (buffer) => {
        this._cache.store(source, buffer);
        audio.setBuffer(buffer);

        then(audio);
      });
    }

    return audio;
  }

  // ---------------------------------------------------------------------------

  _onStart(url, itemsLoaded, itemsTotal) {
    this._itemsStart = itemsLoaded;
    this._itemsTotal = itemsTotal - this._itemsStart;

    this._progress = 0.0;

    // --
    this.transmit('start');
  }

  _onProgress(url, itemsLoaded, itemsTotal) {
    this._itemsLoaded = itemsLoaded - this._itemsStart;
    this._itemsTotal = itemsTotal - this._itemsStart;

    this._progress = this._itemsLoaded / this._itemsTotal;

    // --
    this.transmit('progress');
    itemsLoaded === itemsTotal && this.transmit('complete');
  }

  _onError(url) {
    throw new Error(`RingbaLoader.ERROR: ${url}`);
  }

  // ---------------------------------------------------------------------------

  get current() {
    return this._itemsLoaded;
  }

  get total() {
    return this._itemsTotal;
  }

  get progress() {
    return this._progress;
  }
}
