<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <section>
    <Grid>
      <slot />
    </Grid>
  </section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    margin-bottom: px(60);
  }
</style>
