<script setup>
  const scrollProgress = inject('scrollProgress');
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="scroll-progress">
    <div class="progress" :style="{ transform: `scale3d(1, ${scrollProgress}, 1)` }" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .scroll-progress {
    position: fixed;

    top: 0;
    // left: px(138);

    width: px(1);
    height: 100%;

    background: $white-05;
    .progress {
      transform-origin: center top;
      height: 100%;

      background: $white-10;
    }
  }
</style>
