import { RingbaGL } from './ringba-gl-core';

// --

let RINGBA_GL;

export default (element, config) => {
  return (RINGBA_GL = RINGBA_GL || new RingbaGL(element, config));
};

// --

export { RingbaWorld } from './ringba-gl-world';
export { RingbaGLConfig } from './ringba-gl-config';
export { RingbaTime } from './ringba-gl-time';

export { RingbaEvents } from './ringba-gl-events';
export { RingbaModule } from './ringba-gl-module';

export { RingbaStory } from './ringba-gl-story';
export { RingbaScene } from './ringba-gl-scene';
export { RingbaCamera } from './ringba-gl-camera';

export { RingbaObject } from './ringba-gl-object';
export { RingbaEffect } from './ringba-gl-effect';
