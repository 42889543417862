<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <button><slot /></button>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  button {
    color: $white-40;
  }
</style>
