import RingbaGL, { RingbaEvents, RingbaCamera } from '~/webgl';

import { Scene, Group } from 'three';
import { EffectComposer, RenderPass } from 'postprocessing';

// --

export class RingbaScene extends Scene {
  // ---------------------------------------------------------------------------

  constructor(properties = {}) {
    super();
    RingbaEvents(this);

    // --
    this._camera = properties.camera || this._setupCamera(properties);
    this._renderer = RingbaGL().renderer;

    this._setupComposer(this._setupEffects(properties), properties);

    this.__render = this._composer && properties.effects !== false ? this.__composer : this.__renderer;

    properties.background && (this.background = properties.background);

    // --
    this._create(properties);
  }

  _setupCamera(properties) {
    /* virtual -- returns a camera */
    return this._add(new RingbaCamera(0.1, 100.0, { fov: 50.0 }));
  }

  _setupComposer(effects, properties) {
    if (!effects) return;

    this._composer = new EffectComposer(RingbaGL().renderer);
    this._composer.addPass(new RenderPass(this, this._camera));

    effects.forEach((effect) => {
      this._composer.addPass(effect);
    });
  }

  _setupEffects(properties) {
    /* virtual -- returns a list of effects */
  }

  // --

  _create(properties) {
    /* virtual */
  }

  _add(object, container = this) {
    container.add(object);
    return object;
  }

  _container(object) {
    const container = new Group();
    object && this._add(object, container);

    return container;
  }

  // --

  destroy() {
    this.off();
    this._destroy();

    this._composer && this._composer.dispose();
  }

  _destroy() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  resize(width, height, properties = {}) {
    this._camera.resize(width, height);
    this._composer && this._composer.setSize(width, height);

    this._resize(width, height, properties);
  }

  _resize(width, height, properties) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  enter(properties = {}) {
    this._enter(properties);
  }

  _enter(properties) {
    /* virtual */
  }

  leave(properties = {}) {
    this._leave(properties);
  }

  _leave(properties) {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  loader(time, properties) {
    this._loader(time, properties);
  }

  _loader(time, properties) {
    /* virtual */
  }

  update(time, properties) {
    this._update(time, properties);
  }

  _update(time, properties) {
    /* virtual */
  }

  // --

  render(time) {
    this.__render(time);
  }

  __renderer(time) {
    this._renderer.render(this, this._camera);
  }

  __composer({ dt }) {
    this._composer.render(dt);
  }

  // ---------------------------------------------------------------------------

  get index() {
    return this._index;
  }

  set index(index) {
    this._index = index;
  }

  // --

  get camera() {
    return this._camera;
  }
}
