import RingbaGL, { RingbaEvents, RingbaStory, RingbaScene, Load, Cache } from '~/webgl';

// --

export class RingbaGLConfig {
  // ---------------------------------------------------------------------------

  constructor(WORLD, element, properties = {}) {
    RingbaGL(element, this._setupRingba(WORLD, properties));
    RingbaEvents(WORLD);

    // --
    this._setupRenderer(WORLD, RingbaGL().renderer, properties);
    this._setupModules(WORLD, properties).forEach((module) => RingbaGL().module(module));

    WORLD._story = new RingbaStory(this._setupStory(WORLD, properties));
  }

  // ---------------------------------------------------------------------------

  _setupRingba(WORLD, properties) {
    /* virtual -- returns Ringba configuration */
    return {
      powerPreference: 'high-performance',

      antialias: false,
      stencil: false,
      depth: false,
    };
  }

  _setupRenderer(WORLD, renderer, properties) {
    /* virtual */
  }

  _setupModules(WORLD, properties) {
    /* virtual -- returns a list of modules */
    const load = new Load(RingbaGL().renderer, new Cache(), properties.path);

    load.once('start', (signal) => WORLD._loadStart(properties));
    load.on('progress', (signal) => WORLD._loadProgress(properties));
    load.once('complete', (signal) => WORLD._loadComplete(properties));

    return [load /* additional default modules */];
  }

  _setupStory(WORLD, properties) {
    /* virtual -- returns a list of scenes */
    return [new RingbaScene()];
  }
}
