<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <div class="grid">
    <slot />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .grid {
    @include grid;
  }
</style>
