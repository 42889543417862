import { startTick, stopTick } from '~/utils/anim-frame';

// --

export default (callback) => {
  onMounted(() => {
    startTick(callback);
  });

  onBeforeUnmount(stopTick);

  function stop() {
    stopTick(callback);
  }

  return stop;
};
