<script setup>
  const store = useBaseStore();
  const menuActive = ref(false);
  // const menu = store.getNavBar;

  const onHamburgerClick = () => {
    menuActive.value = !menuActive.value;
  };

  /* TODO: replace with navigation event */
  function onTempMenuClick() {
    menuActive.value = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <header>
    <Grid>
      <GridItem :from="2" gutter>
        <div @click="onHamburgerClick" class="hamburger">
          <IconHamburger />
        </div>
      </GridItem>
      <GridItem :from="4" class="logo"><Logo /></GridItem>
      <GridItem :from="-3" class="login"><LoginButton>Login</LoginButton></GridItem>
      <GridItem :from="-2" :to="-1" class="demo"><Button>Get a Demo</Button></GridItem>
      <!-- <nav>
        <ul>
          <li v-if="menu" v-for="link in menu">
            <NuxtLink :to="link.page.url">{{ link.page.title }}</NuxtLink>
          </li>
        </ul>
      </nav> -->
    </Grid>
  </header>

  <Menu v-if="menuActive" @click="onTempMenuClick" />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  header {
    position: fixed;
    z-index: $layer-header;

    top: px(44);

    @include break-small {
      top: px(30);
    }

    width: 100%;

    .grid {
      align-items: center;
    }

    .hamburger {
      max-width: $gutter;
      cursor: pointer;
      @include hitbox;
    }

    .logo {
      @include break-small {
        @include column(11, 11);

        justify-self: end;
      }
    }

    .login {
      @include break-small {
        display: none;
      }
    }

    .demo {
      button {
        min-width: 100%;
      }

      @include break-small {
        @include column(4, 5);

        margin-left: -$gutter-medium;
        margin-right: -$gutter-medium;
      }
    }
  }
</style>
